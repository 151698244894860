<template>
  <div>
    <header class="mb-12">
      <h1 class="mt-5">Resources</h1>
      <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p>
    </header>

    <v-card outlined elevation="12" max-width="700" class="mx-auto">
      <v-card-title>Organization Resources</v-card-title>
      <v-card-text
        >Manage resources available to organization advisors. For organization specific
        resources, search for the target organization.
        <p class="mt-2 red--text darken-4">
          If no organization is selected, the resource will be available to ALL
          organizations. You can then add organizations to exclude from the resource being
          shared.
        </p></v-card-text
      >
      <v-divider></v-divider>
      <!-- <pre> {{ resource }}</pre> -->
      <v-card-text>
        <v-form ref="form" v-model="valid" class="mt-5">
          <div class="mb-4">
            <SearchSelectOrganization
              v-model="selectedOrganization"
              :required="false"
              :multiple="false"
              label="Search for Organization"
              hint="A single organization to attach this resource to. if no organization is selected, the resource will be available to ALL organizations."
            />
          </div>

          <div class="mb-4">
            <SearchSelectOrganization
              v-model="selectedOrganizationExclude"
              :required="false"
              :multiple="true"
              :disabled="selectedOrganization ? true : false"
              label="Search for Organizations to EXCLUDE"
              hint="Organizations to exclude from the resource being shared, if no orgs are selected above. Organizations listed here will not be able to see this resource."
            />
          </div>

          <v-text-field
            v-model="resource.title"
            label="Title"
            outlined
            required
            :rules="rules.required"
            hint="Name of the resource. Will show in the UI and become filename when downloaded."
            persistent-hint
            type="text"
            class="mb-4"
            prepend-icon="mdi-label-outline"
          ></v-text-field>

          <v-text-field
            v-model="resource.subTitle"
            label="Subtitle"
            outlined
            hint="Supporting info under the title (e.g., Advisor Training)"
            persistent-hint
            type="text"
            class="mb-4"
            prepend-icon="mdi-subtitles-outline"
          ></v-text-field>

          <v-select
            v-model="resource.mediaType"
            :items="mediaTypes"
            :menu-props="{ maxHeight: '400' }"
            required
            :rules="rules.required"
            item-text="label"
            item-value="value"
            hint="Select resource media type"
            label="Media Type"
            clearable
            outlined
            persistent-hint
            prepend-icon="mdi-multimedia"
            class="mb-4"
          ></v-select>

          <v-text-field
            v-if="resource.mediaType === 'wistia' || resource.mediaType === 'youtube'"
            v-model="resource.videoId"
            label="Video ID"
            outlined
            required
            :rules="rules.required"
            :hint="`Enter the ${resource.mediaType.toUpperCase()} ID.`"
            persistent-hint
            type="text"
            class="mb-4"
            prepend-icon="mdi-video-box"
          ></v-text-field>

          <v-file-input
            v-else-if="
              resource.mediaType &&
              (resource.mediaType !== 'wistia' || resource.mediaType !== 'youtube')
            "
            v-model="fileToStore"
            required
            :rules="rules.required"
            :accept="allowedFileTypes"
            :label="`Upload ${resource.mediaType.toUpperCase()}`"
          >
          </v-file-input>

          <v-divider class="my-4" />

          <v-text-field
            v-model="resource.displayOrder"
            label="Display Order"
            outlined
            hint="Order number dictates how resources are laid out in the dashboard."
            persistent-hint
            type="text"
            class="mb-4"
            prepend-icon="mdi-order-numeric-ascending"
          ></v-text-field>
          <!-- <v-file-input
            v-model="thumbnailFileToStore"
            required
            accept="image/*"
            label="Upload thumbnail image"
            hint="Image to represent the resource in the Advisor dashboard. Size: 1280x720 or larger at 16:9 ratio."
            persistent-hint
          >
          </v-file-input> -->

          <!-- <v-select
            v-model="resource.urlTarget"
            :items="urlTargets"
            :menu-props="{ maxHeight: '400' }"
            item-text="label"
            item-value="value"
            hint="How do you want the file to open?"
            label="Media Type"
            clearable
            outlined
            persistent-hint
            prepend-icon="mdi-web-box"
            class="mb-2"
          ></v-select> -->
        </v-form>
      </v-card-text>
      <v-divider class="mt-4"></v-divider>
      <v-card-actions class="py-6 justify-center"
        ><v-btn text color="error" @click="handleReset" class="mr-4">Clear</v-btn>
        <v-btn :disabled="!valid" @click.stop="handleConfirm" color="primary"
          >Create Resource</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-dialog v-model="showSuccessDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text success">
          Resource has been created!
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showSuccessDialog = false" class="mr-4">
            Close
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text error">
          There is a problem!
        </v-card-title>
        <v-card-text>
          <h4>An error has occurred.</h4>
          <p>
            <strong>{{ dialogErrorText }}</strong>
          </p>
          <p>Please refresh and try again.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showErrorDialog = false" class="mr-4">
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="loading"
      color="#091437"
      opacity="0.85"
      absolute="absolute"
      class="text-center"
    >
      <h3 class="mb-4">Creating resource...</h3>
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createResource } from "@/graphql/mutations";
import SearchSelectOrganization from "@/components/SearchSelectOrganization.vue";

const initialResource = function () {
  return {
    title: null,
    organizationId: null,
    mediaType: null,
    s3Media: null,
    mediaThumbnail: null,
    urlTarget: null,
    videoId: null,
  };
};

export default {
  components: { SearchSelectOrganization },
  data() {
    return {
      valid: true,
      loading: false,
      allowedFileTypes:
        ".txt,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,.pptx,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
      showConfirmDialog: false,
      showSuccessDialog: false,
      showErrorDialog: false,
      dialogErrorText: "",
      fileToStore: null,
      thumbnailFileToStore: null,
      resource: new initialResource(),
      reportTypeItems: ["Prospects"],
      selectedOrganization: null,
      selectedOrganizationExclude: null,
      urlTargets: [
        {
          label: "New Tab",
          value: "_blank",
        },
        {
          label: "Same Tab",
          value: "_self",
        },
      ],
      mediaTypes: [
        {
          label: "Wistia",
          value: "wistia",
        },
        {
          label: "Youtube",
          value: "youtube",
        },
        {
          label: "Powerpoint",
          value: "powerpoint",
        },
        {
          label: "PDF",
          value: "pdf",
        },
        {
          label: "Other file type",
          value: "file",
        },
      ],

      rules: {
        required: [
          (v) => {
            return !!v || "This field is required.";
          },
        ],
        requiredArray: [
          (v) => {
            return v.length > 0 || "This field is required.";
          },
        ],
      },
    };
  },
  methods: {
    handleReset() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.resource = new initialResource();
      });
    },
    async handleConfirm() {
      this.loading = true;
      try {
        if (!this.resource.mediaType) {
          return;
        }

        if (this.selectedOrganization) {
          this.resource.organizationId = this.selectedOrganization.id;
        }

        if (this.selectedOrganizationExclude) {
          this.resource.organizationsToExclude = this.selectedOrganizationExclude.map(
            (o) => o.id
          );
        }

        if (
          this.resource.mediaType &&
          this.resource.mediaType !== "wistia" &&
          this.resource.mediaType !== "youtube"
        ) {
          // upload the file
          const file = this.fileToStore;
          const storagePath = this.resource.organizationId
            ? `org/${this.resource.organizationId}/resources/`
            : `org/general/resources/`;

          const response = await Storage.put(`${storagePath}${file.name}`, file, {
            contentType: file.type,
          });

          if (response.key) {
            this.resource.s3Media = {
              bucket: storagePath,
              key: response.key,
              region: "us-east-1",
            };
          }
        }

        if (this.thumbnailFileToStore) {
          // upload the file
          const file = this.thumbnailFileToStore;
          const storagePath = this.resource.organizationId
            ? `org/${this.resource.organizationId}/resources/thumbnails/`
            : `org/general/resources/thumbnails/`;

          const response = await Storage.put(`${storagePath}${file.name}`, file, {
            contentType: file.type,
          });

          console.log("thumbnail file in storage", response);

          if (response.key) {
            this.resource.mediaThumbnail = {
              bucket: storagePath,
              key: response.key,
              region: "us-east-1",
            };
          }
        }

        // Create Resource in DB
        const response = await API.graphql(
          graphqlOperation(createResource, { input: this.resource })
        );

        let newResource = response.data.createResource;

        if (newResource) {
          this.loading = false;
          this.showSuccessDialog = true;
          this.handleReset();
        }
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        console.log("Error calling api to create resource", error);
      } finally {
        // this.loading = false;
        this.showConfirmDialog = false;
      }
    },
  },
};
</script>
